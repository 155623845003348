<template>
  <cau-hinh-tham-so-he-thong />
</template>
<script>
import CauHinhThamSoHeThong from '@/modules/danh-muc/components/pages/danh-muc/tien-ich/CauHinhThamSoHeThong.vue'

export default {
  components: {
    CauHinhThamSoHeThong,
  },
}
</script>
