<template>
  <b-card>
    <div class="d-flex justify-content-start">
      <button
        class="compact ui button text-height"
        @click="refreshCommon"
      >
        <refresh-cw-icon
          size="12"
          class="custom-class line-top"
          :stroke-width="3"
        />
        Tải lại
      </button>
    </div>
    <good-table
      ref="vgTable"
      class="mt-1 fixed-column"
      :columns="columns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      :selectable="false"
      @column-filter="columnFilter"
      @page-change="pageChange"
    >
      <template
        slot="custom-row"
        slot-scope="props"
      >
        <span v-if="props.props.column.field == 'value'">
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="props.props.row.value" />
          <!--eslint-enable-->
        </span>
      </template>
      <template
        slot="actions"
        slot-scope="props"
      >
        <edit-icon
          v-b-tooltip.hover.v-secondary
          title="Cập nhật"
          size="16"
          class="custom-class cursor-pointer mr-1"
          @click="updateCommom(props.props.row)"
        />
      </template>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
      @handle-focus="handleFocusError"
    >
      <template #default>
        <b-row>
          <b-col sm="12">
            <validation-provider
              v-slot="{ errors }"
              key="maCongThanhToan"
              :rules="{ required: true }"
              name="maCongThanhToan"
            >
              <b-form-group
                label-cols="12"
                label-cols-lg="12"
                label="Mã"
                class="required label-black"
              >
                <b-form-input
                  id="key"
                  ref="maCongThanhToan"
                  v-model="form.key"
                  placeholder="Nhập..."
                  :disabled="true"
                />
                <span class="label-noti-validate">{{ errors[0] }}</span>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <div v-if="!form.isHtml">
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true }"
                name="giaTri"
              >
                <b-form-group
                  label-cols="12"
                  label-cols-lg="12"
                  label="Giá trị"
                  class="required label-black"
                >
                  <b-form-textarea

                    id="giaTri"
                    ref="giaTri"
                    v-model="form.value"
                    :class="{required: errors.length > 0}"
                    placeholder="Nhập..."
                    rows="5"
                  />
                  <span class="label-noti-validate">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </div>
            <div v-else>
              <validation-provider
                v-slot="{ errors }"
                :rules="{ required: true }"
                name="myValue"
              >
                <b-form-group
                  label-cols="12"
                  label-cols-lg="12"
                  label="Giá trị"
                  class="required label-black"
                >
                  <ckeditor
                    ref="myValue"
                    v-model="form.value"
                    :editor="editor"
                    :config="editorConfig"
                    class="ckeditor-class"
                  />
                  <span class="label-noti-validate">{{ errors[0] }}</span>
                </b-form-group>
              </validation-provider>
            </div>
          </b-col>
        </b-row>
      </template>
      <div
        v-if="isShow"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.checkValidate()"
        >
          Lưu
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="openModal"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
    <common-modal
      ref="commonnModal"
      title="Xác nhận"
      size="sm"
    >
      Bạn có xác nhận đóng quá trình nhập liệu này không?
      <div
        v-if="isShowFooter"
        slot="footer"
      >
        <b-button
          variant="primary"
          size="sm"
          class="mr-1"
          @click="$refs.commonModal.hideModal(),$refs.commonnModal.hideModal()"
        >
          Đồng ý
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          @click="$refs.commonnModal.hideModal()"
        >
          Đóng
        </b-button>
      </div>
    </common-modal>
  </b-card>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import GoodTable from '@/components/GoodTable.vue'
import {
  BCard,
  BFormGroup,
  BRow,
  BButton,
  BCol,
  BFormInput,
  BFormTextarea,
  VBTooltip,
} from 'bootstrap-vue'
import {
  EditIcon,
  RefreshCwIcon,
} from 'vue-feather-icons'
import addCssSemantic from '@/mixins/mixins'
import CommonModal from '@/modules/danh-muc/components/modal/CommonModal.vue'
// import { quillEditor } from 'vue-quill-editor'
import '@/@core/scss/vue/libs/quill.scss'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor'
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials'
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold'
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic'
import Underline from '@ckeditor/ckeditor5-basic-styles/src/underline'
import Strikethrough from '@ckeditor/ckeditor5-basic-styles/src/strikethrough'
import Subscript from '@ckeditor/ckeditor5-basic-styles/src/subscript'
import Superscript from '@ckeditor/ckeditor5-basic-styles/src/superscript'
import LinkPlugin from '@ckeditor/ckeditor5-link/src/link'
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph'
import ImagePlugin from '@ckeditor/ckeditor5-image/src/image'
import ImageCaptionPlugin from '@ckeditor/ckeditor5-image/src/imagecaption'
import ImageStylePlugin from '@ckeditor/ckeditor5-image/src/imagestyle'
import ImageToolbarPlugin from '@ckeditor/ckeditor5-image/src/imagetoolbar'
import ImageUploadPlugin from '@ckeditor/ckeditor5-image/src/imageupload'
import AutoLink from '@ckeditor/ckeditor5-link/src/autolink'
import Alignment from '@ckeditor/ckeditor5-alignment/src/alignment'
import Table from '@ckeditor/ckeditor5-table/src/table'
import TableToolbar from '@ckeditor/ckeditor5-table/src/tabletoolbar'
import TableCaption from '@ckeditor/ckeditor5-table/src/tablecaption'
import ListStyle from '@ckeditor/ckeditor5-list/src/liststyle'
import HeadingPlugin from '@ckeditor/ckeditor5-heading/src/heading'
import Code from '@ckeditor/ckeditor5-basic-styles/src/code'
import TableProperties from '@ckeditor/ckeditor5-table/src/tableproperties'
import TableCellProperties from '@ckeditor/ckeditor5-table/src/tablecellproperties'
import Base64UploadAdapter from '@ckeditor/ckeditor5-upload/src/adapters/base64uploadadapter'
import PageBreak from '@ckeditor/ckeditor5-page-break/src/pagebreak'
import Font from '@ckeditor/ckeditor5-font/src/font'
import '@/utils/vi'
import _cloneDeep from 'lodash/cloneDeep'
import { compareObjects } from '@/utils/index'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BFormInput,
    RefreshCwIcon,
    BFormTextarea,
    CommonModal,
    GoodTable,
    EditIcon,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      isShow: false,
      isShowFooter: false,
      tagsTemplate: [],
      editor: ClassicEditor,
      editorConfig: {
        language: 'vi',
        placeholder: 'Nhập nội dung...',
        plugins: [
          EssentialsPlugin,
          Base64UploadAdapter,
          LinkPlugin,
          BoldPlugin,
          ItalicPlugin,
          ParagraphPlugin,
          ImagePlugin,
          ImageCaptionPlugin,
          ImageStylePlugin,
          ImageToolbarPlugin,
          ImageUploadPlugin,
          AutoLink,
          Alignment,
          Table,
          TableToolbar,
          ListStyle,
          HeadingPlugin,
          Underline,
          Strikethrough,
          Subscript,
          Superscript,
          Code,
          TableToolbar,
          TableCaption,
          TableProperties,
          TableCellProperties,
          PageBreak,
          Font,
        ],
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'toggleTableCaption',
            'tableProperties',
            'tableCellProperties',
          ],
        },
        toolbar: {
          items: [
            'heading',
            'fontColor',
            'fontSize',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            'subscript',
            'superscript',
            'uploadImage',
            'link',
            'alignment',
            'insertTable',
            'bulletedList',
            'numberedList',
            'pageBreak',
            'code',
            'undo',
            'redo',
          ],
        },
        fontColor: {
          colors: [
            {
              color: 'hsl(0, 0%, 0%)',
              label: 'Black',
            },
            {
              color: 'hsl(0, 0%, 30%)',
              label: 'Dim grey',
            },
            {
              color: 'hsl(0, 0%, 60%)',
              label: 'Grey',
            },
            {
              color: 'hsl(0, 0%, 90%)',
              label: 'Light grey',
            },
            {
              color: 'red',
              label: 'Red',
            },
            {
              color: '#212529',
              label: '#212529',
            },
            {
              color: '#313132',
              label: '#313132',
            },
            {
              color: 'hsl(0, 0%, 100%)',
              label: 'White',
              hasBorder: true,
            },
          ],
        },
        fontSize: {
          options: [
            10,
            12,
            14,
            16,
            18,
          ],
          supportAllValues: true,
        },
      },
      title: 'Thêm mới lĩnh vực',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Mã',
          field: 'key',
          width: '200px',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Giá trị',
          field: 'value',
          width: 'auto',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          field: 'actions',
          tdClass: 'text-center',
          thClass: 'text-center vertical-align-middle',
          width: '100px',
        },
      ],
      rows: [],
      total: 0,
      payload: {
        key: null,
        value: null,
        pageNumber: 1,
        pageSize: 10,
      },
      form: {
        maLinhVuc: null,
        tenLinhVuc: null,
        ghiChu: null,
        status: true,
      },
      beginObject: {},
      isLoading: false,
      size: null,
    }
  },
  created() {
    this.getDataThamSoHeThong()
  },
  methods: {
    getDataThamSoHeThong() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.CAU_HINH_THAM_SO.DS, this.payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    handleOk() {
      // $trimObject => mất /n => bỏ
      this.$axios.put(END_POINTS.CAU_HINH_THAM_SO.SUA, this.form, false).then(res => {
        if (res.data?.succeeded) {
          this.getDataThamSoHeThong()
          this.$toast.success(res.data.message)
          this.$refs.commonModal.hideModal()
        } else {
          this.$toast.error(res.data.message)
        }
      })
    },
    updateCommom(data) {
      this.isShow = true
      this.title = 'Cập nhật tham số hệ thống'
      this.size = 'lg'
      this.form = {
        id: data.id,
        key: data.key,
        value: data.value,
        isModified: data.isModified,
        isHtml: data.isHtml,
      }
      this.$nextTick(() => {
        this.beginObject = _cloneDeep(this.form)
        this.$refs.commonModal.showModal()
      })
    },
    openModal() {
      if (compareObjects(this.beginObject, this.form)) {
        this.$refs.commonModal.hideModal()
        this.$refs.commonnModal.hideModal()
      } else {
        this.$refs.commonnModal.showModal()
        this.isShowFooter = true
      }
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.CAU_HINH_THAM_SO.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      this.payload = {
        key: data?.key ? data.key.replace(/\s+/g, ' ').trim() : '',
        value: data?.value ? data.value.replace(/\s+/g, ' ').trim() : '',
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.CAU_HINH_THAM_SO.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    refreshCommon() {
      this.payload = {
        key: null,
        value: null,
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.vgTable.$refs.vbTables.reset()
      this.columnFilter()
    },
    handleFocusError(first) {
      if (first) {
        const field = first[0]
        if (['myValue'].includes(field)) {
          // this.$refs[field].autofocus = true
        } else {
          this.$refs[field].focus()
        }
      }
    },
  },
}
</script>
